.landing-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .landing-header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .landing-header h1 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  .quick-actions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-bottom: 3rem;
  }
  
  .action-card {
    background: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .action-card:hover {
    transform: translateY(-5px);
  }
  
  .action-card h3 {
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .action-card p {
    color: #666;
  }
  
  .recent-activity {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .recent-activity h2 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  .customer-info-section {
    margin: 2rem 0;
    padding: 1rem;
  }
  
  .info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .info-card {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .info-card h3 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  .info-card.highlight {
    background: #f8f9fa;
    border: 2px solid #007bff;
  }
  
  .due-amount {
    font-size: 2rem;
    font-weight: bold;
    color: #dc3545;
    text-align: center;
  }
  
  .info-card ul {
    list-style: none;
    padding: 0;
  }
  
  .info-card li {
    padding: 0.5rem 0;
    border-bottom: 1px solid #eee;
  }
  
  .pay-now-button {
    width: 100%;
    padding: 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 1rem;
  }
  
  .pay-now-button:hover {
    background-color: #218838;
  }
  
  .pay-now-button:active {
    transform: scale(0.98);
  }


  .renew-button {
    width: 100%;
    padding: 12px;
    background-color: #265ef7;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 1rem;
  }
  
  .renew-button:hover {
    background-color: #0000ff;
  }
  
  .renew-button:active {
    transform: scale(0.98);
  }


  .ledger-section {
    margin: 2rem 0;
    padding: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .ledger-section h2 {
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .ledger-table-container {
    overflow-x: auto;
  }
  
  .ledger-table {
    width: 100%;
    border-collapse: collapse;
    background: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .ledger-table th,
  .ledger-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .ledger-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #495057;
  }
  
  .ledger-table tr:hover {
    background-color: #f8f9fa;
  }
  
  .ledger-table td:nth-child(3),
  .ledger-table td:nth-child(4),
  .ledger-table td:nth-child(5) {
    text-align: right;
  }
  
  .ledger-table td:nth-child(3) {
    color: #dc3545; /* Red for debit */
  }
  
  .ledger-table td:nth-child(4) {
    color: #28a745; /* Green for credit */
  }
  
  /* Make the table responsive */
  @media (max-width: 768px) {
    .ledger-table {
      font-size: 0.9rem;
    }
    
    .ledger-table th,
    .ledger-table td {
      padding: 0.75rem;
    }
  }